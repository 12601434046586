<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="#3db2ff">
          <v-btn icon dark @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>พิมพ์ตั๋วเข้าร่วมกิจกรรม</v-toolbar-title>
        </v-toolbar>
        <v-row class="mx-4">
          <v-col cols="12" md="6" class="mt-6">
            <div>
              <v-card class="px-4 py-4">
                <div>
                  <span>หมายเลขออเดอร์ : </span
                  ><span class="font-weight-bold">{{ order.orderId }}</span>
                </div>
                <div class="mt-4">
                  <span>จำนวนผู้เข้าร่วม: </span
                  ><span class="font-weight-bold">{{ order.joinQty }}</span>
                </div>
              </v-card>
              <v-card class="mt-4">
                <v-list three-line>
                  <v-card-title class="mx-4">เลือกผู้เข้าร่วม</v-card-title>
                  <template v-for="(item, index) in items">
                    <v-divider :key="index" :inset="item.inset"></v-divider>

                    <v-list-item
                      v-if="order.type !== 'group'"
                      :key="item.firstname"
                    >
                      <v-list-item-avatar color="blue">
                        <span class="white--text text-h5">{{
                          item.firstname.slice(0, 1)
                        }}</span>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          :class="
                            item.close === true
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ item.firstname }} {{ item.lastname }} (อายุ
                          {{ diffYear(item.birthday) }} ปี)
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action v-if="order.type !== 'group'">
                        <v-btn @click="closePeople(index)" icon>
                          <v-icon v-if="!item.close" color="grey lighten-1"
                            >mdi-close</v-icon
                          >
                          <v-icon v-else color="grey lighten-1"
                            >mdi-check</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item v-else :key="item.firstname">
                      <v-list-item-avatar color="blue">
                        <span class="white--text text-h5">{{
                          order.organizationName.slice(0, 1)
                        }}</span>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title
                          :class="
                            item.close === true
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ order.organizationName }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action v-if="order.type !== 'group'">
                        <v-btn @click="closePeople(index)" icon>
                          <v-icon v-if="!item.close" color="grey lighten-1"
                            >mdi-close</v-icon
                          >
                          <v-icon v-else color="grey lighten-1"
                            >mdi-check</v-icon
                          >
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="mt-6">
            <v-card color="px-4 py-4">
              <div class="d-flex justify-space-between">
                <div>Preview</div>
                <v-btn
                  v-print="printObj"
                  color="green"
                  class="font-weight-bold"
                  dark
                  ><v-icon class="mr-2 my-0 py-0">mdi-printer</v-icon>พิมพ์
                </v-btn>
              </div>
              <v-card
                v-for="(item, index) in itemPrint"
                :key="index"
                class="my-4"
              >
                <v-list three-line>
                  <template>
                    <v-subheader
                      :key="item.header"
                      v-text="item.header"
                    ></v-subheader>

                    <v-divider :key="index" :inset="item.inset"></v-divider>

                    <v-list-item
                      v-if="order.type !== 'group'"
                      :key="item.firstname"
                    >
                      <v-list-item-avatar color="blue">
                        <span class="white--text text-h5">{{
                          item.firstname.slice(0, 1)
                        }}</span>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          :class="
                            item.close === true
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ item.firstname }} {{ item.lastname }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-else :key="item.firstname">
                      <v-list-item-avatar color="blue">
                        <span class="white--text text-h5">{{
                          order.organizationName.slice(0, 1)
                        }}</span>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          :class="
                            item.close === true
                              ? 'text-decoration-line-through'
                              : ''
                          "
                        >
                          {{ order.organizationName }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-card>

              <div v-if="order.type === 'group'" id="printArea">
                <div
                  style="padding-top: 27rem"
                  v-for="i in order.joinQty"
                  :key="i"
                  class="print"
                >
                  <div v-if="order.type === 'group'" class="rotated">
                    <!-- <p>{{ wrapWord(order.organizationName) }}</p> -->
                    <!-- <p>{{ formatAge(item.birthday) }} Y.</p> -->
                  </div>
                  <div
                    v-if="order.type !== 'group' && order.branch !== 'sathorn'"
                    class="rotated"
                  >
                    <!-- <p>{{ item.firstname }}</p> -->
                    <!-- <p>{{ formatAge(item.birthday) }} Y.</p> -->
                  </div>
                  <div>
                    <!-- <img
                      style="width 6rem; height: 6rem ; margin-top: 4rem;"
                      :src="order.qrcode"
                    /> -->
                  </div>
                  <div style="margin-top: 5rem" class="rotated">
                    <span class="text-center">
                      {{ formatDate(order.schedule.date) }}
                    </span>
                    <div>
                      {{
                        formatSchedule(
                          order.schedule.startTime,
                          order.schedule.endTime
                        )
                      }}
                    </div>
                  </div>
                  <div class="rotated">
                    <img
                      style="width 5rem; height: 5rem ; margin-left: 4rem;"
                      src="/svg/PlanToys_Logo-01.svg"
                    />
                  </div>
                </div>
              </div>

              <div v-else id="printArea">
                <div
                  style="padding-top: 27rem"
                  v-for="(item, index) in itemPrint"
                  :key="index"
                  class="print"
                >
                  <div v-if="order.type === 'group'" class="rotated">
                    <p>{{ item.organizationName }}</p>
                    <!-- <p>{{ formatAge(item.birthday) }} Y.</p> -->
                  </div>
                  <div
                    v-if="order.type !== 'group' && order.branch !== 'sathorn'"
                    class="rotated"
                  >
                    <!-- <p>{{ item.firstname }}</p> -->
                    <!-- <p>{{ formatAge(item.birthday) }} Y.</p> -->
                  </div>
                  <div>
                    <!-- <img
                      style="width 6rem; height: 6rem ; margin-top: 4rem;"
                      :src="order.qrcode"
                    /> -->
                  </div>
                  <div style="margin-top: 5rem" class="rotated">
                    <span class="text-center">
                      {{ formatDate(order.schedule.date) }}
                    </span>
                    <div>
                      {{
                        formatSchedule(
                          order.schedule.startTime,
                          order.schedule.endTime
                        )
                      }}
                    </div>
                  </div>
                  <div class="rotated">
                    <img
                      style="width 5rem; height: 5rem ; margin-left: 4rem;"
                      src="/svg/PlanToys_Logo-01.svg"
                    />
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    itemPrint() {
      return this.items.filter((item) => item.close === false);
    },
  },
  mounted() {
    const arr = [];
    if (this.order.type === "group") {
      arr.push({ ...this.order.member, close: false });
    } else {
      for (let i = 0; i < this.order.participant.length; i++) {
        if (this.diffYear(this.order.participant[i].birthday) < 12) {
          arr.push({ ...this.order.participant[i], close: true });
        } else {
          arr.push({ ...this.order.participant[i], close: false });
        }
      }
    }
    this.items = arr;
  },
  data() {
    return {
      items: [],
      dialog: true,
      printObj: {
        id: "printArea",
      },
    };
  },
  methods: {
    wrapWord(val) {
      if (val.length > 15) {
        return `${val.slice(0, 15)}...`;
      }
      return val;
    },
    formatAge(val) {
      return dayjs().diff(val, "year");
    },
    formatDate(val) {
      return dayjs(val).locale("en").format("DD.MMM.YY");
    },
    closePeople(index) {
      this.items[index].close = !this.items[index].close;
    },
    diffYear(date) {
      return dayjs().diff(date, "year");
    },
  },
};
</script>
<style>
#printArea {
  display: none;
}

.rotated {
  transform: rotate(90deg);
}
.print {
  height: 28cm;
  /* height: 14cm; */
}
@media print {
  .print {
    height: 100vh;
    /* margin: 0 !important; */
    /* padding: 0 !important; */
    overflow: hidden;
    page-break-after: avoid;
    page-break-before: avoid;
  }
  #printArea {
    display: block;
  }
  .rotated {
    transform: rotate(90deg);
  }
  footer {
    page-break-after: always;
  }
}

.bt {
  margin-top: 10rem;
  z-index: 99999;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"#3db2ff"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("พิมพ์ตั๋วเข้าร่วมกิจกรรม")])],1),_c('v-row',{staticClass:"mx-4"},[_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12","md":"6"}},[_c('div',[_c('v-card',{staticClass:"px-4 py-4"},[_c('div',[_c('span',[_vm._v("หมายเลขออเดอร์ : ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.order.orderId))])]),_c('div',{staticClass:"mt-4"},[_c('span',[_vm._v("จำนวนผู้เข้าร่วม: ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.order.joinQty))])])]),_c('v-card',{staticClass:"mt-4"},[_c('v-list',{attrs:{"three-line":""}},[_c('v-card-title',{staticClass:"mx-4"},[_vm._v("เลือกผู้เข้าร่วม")]),_vm._l((_vm.items),function(item,index){return [_c('v-divider',{key:index,attrs:{"inset":item.inset}}),(_vm.order.type !== 'group')?_c('v-list-item',{key:item.firstname},[_c('v-list-item-avatar',{attrs:{"color":"blue"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(item.firstname.slice(0, 1)))])]),_c('v-list-item-content',[_c('v-list-item-title',{class:item.close === true
                            ? 'text-decoration-line-through'
                            : ''},[_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" (อายุ "+_vm._s(_vm.diffYear(item.birthday))+" ปี) ")])],1),(_vm.order.type !== 'group')?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closePeople(index)}}},[(!item.close)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-close")]):_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-check")])],1)],1):_vm._e()],1):_c('v-list-item',{key:item.firstname},[_c('v-list-item-avatar',{attrs:{"color":"blue"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.order.organizationName.slice(0, 1)))])]),_c('v-list-item-content',[_c('v-list-item-title',{class:item.close === true
                            ? 'text-decoration-line-through'
                            : ''},[_vm._v(" "+_vm._s(_vm.order.organizationName)+" ")])],1),(_vm.order.type !== 'group')?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closePeople(index)}}},[(!item.close)?_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-close")]):_c('v-icon',{attrs:{"color":"grey lighten-1"}},[_vm._v("mdi-check")])],1)],1):_vm._e()],1)]})],2)],1)],1)]),_c('v-col',{staticClass:"mt-6",attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"color":"px-4 py-4"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v("Preview")]),_c('v-btn',{directives:[{name:"print",rawName:"v-print",value:(_vm.printObj),expression:"printObj"}],staticClass:"font-weight-bold",attrs:{"color":"green","dark":""}},[_c('v-icon',{staticClass:"mr-2 my-0 py-0"},[_vm._v("mdi-printer")]),_vm._v("พิมพ์ ")],1)],1),_vm._l((_vm.itemPrint),function(item,index){return _c('v-card',{key:index,staticClass:"my-4"},[_c('v-list',{attrs:{"three-line":""}},[[_c('v-subheader',{key:item.header,domProps:{"textContent":_vm._s(item.header)}}),_c('v-divider',{key:index,attrs:{"inset":item.inset}}),(_vm.order.type !== 'group')?_c('v-list-item',{key:item.firstname},[_c('v-list-item-avatar',{attrs:{"color":"blue"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(item.firstname.slice(0, 1)))])]),_c('v-list-item-content',[_c('v-list-item-title',{class:item.close === true
                            ? 'text-decoration-line-through'
                            : ''},[_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")])],1)],1):_c('v-list-item',{key:item.firstname},[_c('v-list-item-avatar',{attrs:{"color":"blue"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.order.organizationName.slice(0, 1)))])]),_c('v-list-item-content',[_c('v-list-item-title',{class:item.close === true
                            ? 'text-decoration-line-through'
                            : ''},[_vm._v(" "+_vm._s(_vm.order.organizationName)+" ")])],1)],1)]],2)],1)}),(_vm.order.type === 'group')?_c('div',{attrs:{"id":"printArea"}},_vm._l((_vm.order.joinQty),function(i){return _c('div',{key:i,staticClass:"print",staticStyle:{"padding-top":"27rem"}},[(_vm.order.type === 'group')?_c('div',{staticClass:"rotated"}):_vm._e(),(_vm.order.type !== 'group' && _vm.order.branch !== 'sathorn')?_c('div',{staticClass:"rotated"}):_vm._e(),_c('div'),_c('div',{staticClass:"rotated",staticStyle:{"margin-top":"5rem"}},[_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.order.schedule.date))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.formatSchedule( _vm.order.schedule.startTime, _vm.order.schedule.endTime ))+" ")])]),_c('div',{staticClass:"rotated"},[_c('img',{staticStyle:{"height":"5rem","margin-left":"4rem"},attrs:{"src":"/svg/PlanToys_Logo-01.svg"}})])])}),0):_c('div',{attrs:{"id":"printArea"}},_vm._l((_vm.itemPrint),function(item,index){return _c('div',{key:index,staticClass:"print",staticStyle:{"padding-top":"27rem"}},[(_vm.order.type === 'group')?_c('div',{staticClass:"rotated"},[_c('p',[_vm._v(_vm._s(item.organizationName))])]):_vm._e(),(_vm.order.type !== 'group' && _vm.order.branch !== 'sathorn')?_c('div',{staticClass:"rotated"}):_vm._e(),_c('div'),_c('div',{staticClass:"rotated",staticStyle:{"margin-top":"5rem"}},[_c('span',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.order.schedule.date))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.formatSchedule( _vm.order.schedule.startTime, _vm.order.schedule.endTime ))+" ")])]),_c('div',{staticClass:"rotated"},[_c('img',{staticStyle:{"height":"5rem","margin-left":"4rem"},attrs:{"src":"/svg/PlanToys_Logo-01.svg"}})])])}),0)],2)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }